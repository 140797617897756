<template>
  <v-list
    dense
    nav
  >
    <v-list-item
      v-for="(category, index) in categories" :key="index"

      :to="{name:'feed-connectors-nod-integration',params:{categoryId:category.id}}"
      link
    >

      <v-list-item-content>
        <v-list-item-title> {{ category.name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
  
</template>

<script>
export default {
  data () {
    return {
      categories: []
    }
  },
  created () {
    this.$http.get('product-categories/parents-only')
      .then(({data}) => {
        this.categories = data
      })
  }
}
</script>

