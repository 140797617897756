<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        :to="{name:'feed-connectors-nod-attributes', params:{categoryId:categoryId}}"
        class="mx-2 mb-2 secondary-button"
        color=" lighten-4"
        elevation="1"
      >
        <v-icon class="mr-2" small>{{ icon }}</v-icon>
        {{ buttonLabel }}
      </v-btn>
    </template>
    <span>Vezi atributele</span>
  </v-tooltip>
</template>

<script>
import NewButton from '@/components/general-form/NewButton'

export default {
  components: {NewButton},
  props: {
    categoryId: {
      required: true
    },
    buttonLabel: {
      type: String,
      default: 'Vezi atributele'
    },
    icon:{
      type:String,
      default: 'fal fa-eye'
    }
  }
}
</script>

