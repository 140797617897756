<template>
  <div>
    <v-checkbox
        v-for="(quality, index) in qualities"
        :key="index"
        v-model="selectedQualitiesForThisCategory"
        :hide-details="true"
        :label="quality.name"
        :value="quality.id"
        multiple
    />
  </div>
</template>

<script>
export default {
  name: 'QualitySelector',
  props: {
    qualities: {
      required: true
    },
    categoryId: {
      required: true
    }
  },
  computed: {
    selectedQualitiesForThisCategory: {
      get() {
        return this.$store.getters["productGenerator/selectedQualities"][this.categoryId] || []
      },
      set(value) {
        this.$store.dispatch('productGenerator/setQualitiesForCategory', {
          categoryId: this.categoryId,
          qualities: value
        })
      }
    }
  }
}
</script>
